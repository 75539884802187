import React from "react"
import { Container } from "@material-ui/core"
import Footer from "../components/footer/Footer"
import Nav from "../components/navigation/Nav"
import PageBanner from "../components/PageBanner"
import SEO from "../components/SEO"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp"
import Typography from "@material-ui/core/Typography"
import { styled } from "@material-ui/core/styles"

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}))

const SideGamesPage = ({ location }) => {
  const [expanded, setExpanded] = React.useState("panel1")

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <SEO
        title="Side Games"
        description="Explore the list of side games that will be featured at kumite in tennessee 2021"
        keywords="KIT, KITX, summer bash, game galaxy, tennessee, texas, fgc, tournament, fighing games, esports"
      />
      <header>
        <Nav pathname={location.pathname} />
        <PageBanner title="Side Games" />
      </header>
      <main style={{ background: "#d4d4d4", width: "100%", padding: "60px 0" }}>
        <Container maxWidth="lg">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Pokken Tournament DX (Switch)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>Start time: Friday 8 PM</Typography>
                </li>
                <li>
                  <Typography>Registration deadline: Friday 7 PM</Typography>
                </li>
                <li>
                  <Typography>Capped at 32 players</Typography>
                </li>
                <li>
                  <Typography>Game Settings: LAN Mode</Typography>
                </li>
                <li>
                  <Typography>Round Points: 2</Typography>
                </li>
                <li>
                  <Typography>Skill Level: Off</Typography>
                </li>
                <li>
                  <Typography>Round Time: 80 seconds</Typography>
                </li>
                <li>
                  <Typography>
                    Character Select: The loser of a game may change fighter
                    Pokemon for the next game of the set. Winner must stay.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Both players may change "Support Pokemon" and "Cheer Skill"
                    between games.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Ultra Street Fighter IV</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>Start time: Friday 8 PM</Typography>
                </li>
                <li>
                  <Typography>Registration deadline: Friday 7 PM</Typography>
                </li>
                <li>
                  <Typography>Capped at 32 players</Typography>
                </li>
                <li>
                  <Typography>2 out of 3 rounds per game</Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games
                  </Typography>
                </li>
                <li>
                  <Typography>Round time 99 seconds</Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Rivals of Aether (PC)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>Start time: Saturday 8 PM</Typography>
                </li>
                <li>
                  <Typography>Registration deadline: Saturday 6 PM</Typography>
                </li>
                <li>
                  <Typography>Capped at 32 players</Typography>
                </li>
                <li>
                  <Typography>
                    3 stocks, 8:00 minute timer, Aether Stages “OFF”, Turbo Mode
                    “OFF”
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>Mario Kart Deluxe (Switch)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>Start Time: Sunday 2 PM</Typography>
                </li>
                <li>
                  <Typography>Registration deadline: Sunday 1 PM</Typography>
                </li>
                <li>
                  <Typography>Capped at 32 players</Typography>
                </li>
                <li>
                  <Typography>Heats of 4 players. 5 races. 150cc.</Typography>
                </li>
                <li>
                  <Typography>
                    First race is Mario Circuit, next 4 races are player-chosen
                    in the order of placement in Mario Circuit.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Top 2 scores move on to the next round
                  </Typography>
                </li>
                <li>
                  <Typography>
                    In the event of a tie, there is a 1v1 tier breaker race on
                    Baby Park, full toxicity
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Typography>Ultimate Marvel vs. Capcom 3</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>Start Time: Sunday 11 AM</Typography>
                </li>
                <li>
                  <Typography>Registration deadline: Sunday 10 AM</Typography>
                </li>
                <li>
                  <Typography>Capped at 32 players</Typography>
                </li>
                <li>
                  <Typography>
                    99 second timer, best 3 of 5 for all matches
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Galactus mode or triple same character mods banned
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Typography>Melty Blood Lumina (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>Start Time: Sunday 1 PM</Typography>
                </li>
                <li>
                  <Typography>Registration deadline: Sunday 11 AM</Typography>
                </li>
                <li>
                  <Typography>Capped at 64 players</Typography>
                </li>
                <li>
                  <Typography>2 out of 3 rounds per game</Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </Container>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default SideGamesPage
